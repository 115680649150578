nj-counter {
  --size: 50px;
  --aspect-ratio: 1.0;
  --speed: 0.5s;

  display: block;
  width: var(--size);
  height: calc(var(--size) * var(--aspect-ratio));

  position: relative;

  .animation-container {
    position: absolute;
    width: 100%;
    transition-duration: var(--speed);

    .counter-item {
      width: var(--size);
      height: calc(var(--size) * var(--aspect-ratio));
    }
  }

  &.default {
    overflow: hidden;

    .animation-container {
      transition-timing-function: ease-in-out;
    }

    .counter-item {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
