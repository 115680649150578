nj-countdown {
  --counter-size: 3em;
  --aspect-ratio: 1.0;

  &>div {
    display: flex;
    flex-direction: row;

    [data-slot] {
      flex-grow: 1;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .value {
        width: var(--counter-size);
        height: calc(var(--counter-size) * var(--aspect-ratio));

        position: relative;
      }
    }
  }
}
